body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@keyframes animateBg {
  0%
 {
   transform:rotate(0deg)
 }
  25%
 {
   transform:rotate(90deg)
 }
 50%
 {
   transform:rotate(180deg)
 }
  75%
 {
   transform:rotate(270deg)
 }
 100% {
   transform:rotate(360deg)
 }
}